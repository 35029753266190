<template>
  <div class="sidebar__inner">
    <div
      class="blk-access stickyElem"
      :class="{ isBrowser: isBrowser }"
    >
      <template v-if="isMobile">
        <div
          v-if="getConseiller !== '' && getCalendlyUrl !== ''"
          class="contactOwner"
        >
          <p>{{ $t('menu.owner') }}</p>
          <p class="ownerName">
            {{ getConseiller }}
          </p>
          <a
            :href="getCalendlyUrl"
            target="_blank"
            class="bouton bouton-sty_1"
          >{{ $t('menu.rendezVous') }}</a>
          <div
            v-if="getResponsable !== null "
            class="contactResponsable"
          >
            <p>
              {{ $t('menu.responsable') }}
              <em
                class="icon-info"
                :data-tooltip="$t('menu.responsable-infobulle')"
              >
                <strong>&nbsp;</strong>
              </em>
            </p>
            <p class="ownerName">
              {{ getResponsable }}
            </p>
            <a
              v-if="checkEntretien"
              class="bouton bouton-sty_1"
              :class="{ confirmResponsible: confirmResponsible }"
              @click="setConfirmResponsible"
            ><span class="confirm-text">{{ confirmText }}</span></a>
            <div
              v-else
              class="responsableSuccess"
            >
              <div class="circle active">
                <div class="checkMark" />
              </div>
              <p>{{ $t('menu.responsableSuccess') }}</p>
            </div>
            <span>{{ error }}</span>
          </div>
        </div>
        <ul>
          <fast-menu-element
            v-for="(item, index) in items"
            :key="index"
            :class="{ hasSubmenu: item.hasSubs }"
            :item="item"
          />
        </ul>
        <p
          v-if="getDateDerniereConnexion"
          class="dateDerniereConnexion"
        >
          {{ getDateDerniereConnexion }}
        </p>
      </template>
      <template v-else>
        <h2>- {{ $t('menu.fastTitle') }} -</h2>
        <div
          v-if="getConseiller !== '' && getCalendlyUrl !== ''"
          class="contactOwner"
        >
          <p>{{ $t('menu.owner') }}</p>
          <p class="ownerName">
            {{ getConseiller }}
          </p>
          <a
            :href="getCalendlyUrl"
            target="_blank"
            class="bouton bouton-sty_1"
          >{{ $t('menu.rendezVous') }}</a>
          <div
            v-if="getResponsable !== null "
            class="contactResponsable"
          >
            <p>
              {{ $t('menu.responsable') }}
              <em
                class="icon-info"
                :data-tooltip="$t('menu.responsable-infobulle')"
              >
                <strong>&nbsp;</strong>
              </em>
            </p>
            <p class="ownerName">
              {{ getResponsable }}
            </p>
            <a
              v-if="checkEntretien"
              class="bouton bouton-sty_1"
              :class="{ confirmResponsible: confirmResponsible }"
              @click="setConfirmResponsible"
            ><span class="confirm-text">{{ confirmText }}</span></a>
            <div
              v-else
              class="responsableSuccess"
            >
              <div class="circle active">
                <div class="checkMark" />
              </div>
              <p>{{ $t('menu.responsableSuccess') }}</p>
            </div>
            <span>{{ error }}</span>
          </div>
        </div>
        <ul>
          <fast-menu-element
            v-for="(item, index) in items"
            :key="index"
            :class="{ hasSubmenu: item.hasSubs }"
            :item="item"
          />
        </ul>
        <p
          v-if="getDateDerniereConnexion"
          class="dateDerniereConnexion"
        >
          {{ getDateDerniereConnexion }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isMobile, isBrowser } from 'mobile-device-detect';
import FastMenuElement from '@/components/utils/FastMenuElement.vue';
import { sfNativeWSHTTP } from '@/axios/index';

export default {
  name: 'FastMenu',
  components: {
    'fast-menu-element': FastMenuElement,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isMobile,
      isBrowser,
      confirmResponsible : false,
      checkEntretien : false,
      error : "",
    };
  },
  computed: {
    ...mapGetters(['sfUser']),
    getConseiller() {
      return (this.sfUser) ? this.sfUser.ownerContactInfo.conseiller : '';
    },
    getCalendlyUrl() {
      return (this.sfUser) ? this.sfUser.ownerContactInfo.calendlyUrl : '';
    },
    getDateDerniereConnexion() {
      const lastConnection = this.sfUser?.dateDerniereConnexion?.oldValue;
      if (lastConnection === null || lastConnection === undefined) {
        return false;
      }
      return this.formatDateTime(lastConnection);
    },
    getResponsable() {
      return this.sfUser.ownerContactInfo.responsableDeCompte;
    },
    confirmText() {
      return this.confirmResponsible ? this.$t('menu.confirmerEntretien') : this.$t('menu.entretien');
    }
  },
  mounted () {
    this.checkEntretien = this.sfUser.ownerContactInfo.afficheBtnEntretien;
  },
  methods: {
    formatDateTime(dateString) {
      const date = new Date(dateString);

      const formattedDate = new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: 'long', year: 'numeric' }).format(date);

      const hours = date.getUTCHours() + 2
      const minutes = date.getUTCMinutes();
      const formattedTime = `${hours}h${minutes.toString().padStart(2, '0')}`;

      return this.$t('menu.lastConnected', { date: formattedDate, heure: formattedTime });
    },
    setConfirmResponsible() {
      if (this.confirmResponsible) {
        this.handleSfFlow(this.sfUser.idContact, 'demande_entretien', 'Mon compte');
      } else {
        this.confirmResponsible = true;
      }
    },
    handleSfFlow(id, action, origine) {
        const body = {
          "inputs": [
            {
              ID: id,
              Action: action,
              Origine: origine
            }
          ]
        };
        sfNativeWSHTTP.post('actions/custom/flow/external_app_action', body)
        .then((result) => {
          if (result.status === 200) {
            this.error = '';
            this.checkEntretien = false;
          } else {
            this.error = JSON.stringify(result);
          }
        }).catch((err) => {
          this.error = JSON.stringify(err.message);
        });
      }
  }
};
</script>

<style lang="scss">
.stickyElem.isBrowser {
  background: $white;
  width: 16.6666666667%;
  position: fixed;
  top: 154px;
}

.scrollUp+main .stickyElem.isBrowser {
  top: 54px;
}

.scrolled-past .stickyElem.isBrowser {
  position: relative;
  width: 100%;
}

.contactOwner {
  padding: 12px 40px 12px 15px;
  background-color: #f0e6d8;
  @media screen and (min-width:$breakpoint-m) and (max-width: $breakpoint-l) {
    padding: 12px 15px;
  }
  p {
    font-size: 0.85em;
  }
  a {
    padding: 15px 10px !important;
    min-width: 100%;
    color:#fff !important;
    text-transform: unset !important;
    cursor: pointer;
    font-size: 0.9em !important;
    margin-top: 5px;
    font-family: "SourceSansPro-Semibold", sans-serif !important;
    @media screen and (min-width:$breakpoint-m) and (max-width: $breakpoint-l) {
      font-size: 0.8em !important;
    }
  }
  .ownerName {
    font-size: 0.9em;
    font-family: "SourceSansPro-Semibold", sans-serif;
    padding: 0;
  }
  .contactResponsable{
    padding-top: 20px;
    .bouton {
      display: inline-block;
      transition: transform 1s;
    }
    .confirmResponsible {
      transform: rotateX(180deg);
      background-color: #c6a166;
      .confirm-text {
        transform: rotateX(180deg);
      }
    }
    .confirm-text {
      display: inline-block;
      transform: rotateX(0deg);
      transition: transform 0.3s;
    }
    .responsableSuccess {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
      .circle {
        position: relative;
        background: #3fe65a;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        @media only screen and (min-width: 991px) and (max-width: 1600px){
          width: 40px;
        }
      }
      .checkMark {
        position: absolute;
        transform: rotate(50deg) translate(-50%, -50%);
        left: 25%;
        top: 45%;
        height: 10px;
        width: 6px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
    .icon-info strong:before {
      margin: 0;
    }
  }
}

.contactOwnerMobile {
  padding: 20px 35px;
  .ownerName {
    font-family: "SourceSansPro-Semibold", sans-serif !important;
    color: #000 !important;
  }
}

.dateDerniereConnexion {
  padding: 20px 15px;
  font-size: 0.85em;
  @media screen and (max-width: $breakpoint-m) {
    padding: 20px 35px;
    color: #fff;
  }
}
</style>
