import { drupalHTTP } from '@/axios';
import lodash from "lodash";
import {
  ghcInitialState,
} from '@/store/modules/functions-utils';

const gammeshCommerce = {
  state: () => (ghcInitialState()),
  mutations: {
    INIT_SUPPORTS_ERROR(state) {
      state.supportsErrors = [];
    },
    ADD_SUPPORTS_ERROR(state, error) {
      state.supportsErrors.push(error);
    },
    SET_GAMMESH_COMMERCE_COURS(state, gammeshencours) {
      state.supports = lodash.orderBy(gammeshencours.nodes, ["dateFin"], ["asc"]);
      state.mentions = gammeshencours.mentions;
    },
  },
  actions: {
    load_gammes_h_commerce_en_cours(context) {
      context.commit('INIT_SUPPORTS_ERROR');

      drupalHTTP.get('offres-en-cours-gh')
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_GAMMESH_COMMERCE_COURS', response.data);
          } else {
            context.commit('ADD_SUPPORTS_ERROR', response);
          }
        })
        .catch((error) => {
          context.commit('ADD_SUPPORTS_ERROR', error);
        });
    },
  },
  getters: {
    ghCommerce: (state) => state.supports,
    ghCommerceMentions: (state) => state.mentions,
    ghCommerceErrror: (state) => state.supportsErrors,
  },
};

export default gammeshCommerce;
