const fastMenuItems = [
  {
    slot: 'Suivre mes Gammes H',
    to: '#',
    context: 'gammesh',
    hasSubs: true,
    subs: [
      {
        slot: 'Mes prochains gains potentiels',
        to: '/#prochains-gains-potentiels',
      },
      {
        slot: 'Mon portefeuille',
        to: '/mon-portefeuille-gammes-h#mon-portefeuille-gammes-h',
      },
    ],
  },
  {
    slot: 'Réaliser une opération',
    to: '#',
    context: 'prospect',
    hasSubs: true,
    subs: [
      {
        slot: 'Souscrire',
        to: 'newContrat',
      },
    ],
  },
  {
    slot: 'Réaliser une opération',
    to: 'realiserUnOpe',
    context: 'manyContracts',
    hasSubs: true,
    subs: [
      {
        slot: 'Souscrire',
        to: 'newContrat',
      },
    ],
  },
  {
    slot: 'Réaliser une opération',
    to: '#',
    context: 'oneContract',
    hasSubs: true,
    subs: [
      {
        slot: 'Verser',
        to: 'versement',
      },
      {
        slot: 'Arbitrer',
        to: 'arbitrage',
      },
      {
        slot: 'Mettre en place le Mandat Gammes H',
        to: 'mandat',
      },
      {
        slot: 'Souscrire',
        to: 'newContrat',
      },
    ],
  },
  {
    slot: 'Réaliser une opération',
    to: '#',
    context: 'oneContractMandat',
    hasSubs: true,
    subs: [
      {
        slot: 'Verser',
        to: 'versement',
      },
      {
        slot: 'Souscrire',
        to: 'newContrat',
      },
    ],
  },
  {
    slot: 'À l\'affiche',
    to: 'produit-affiche',
    context: 'all',
  },
  {
    slot: 'Nous contacter',
    to: 'contact',
    context: 'all',
  }
];

export default fastMenuItems;
